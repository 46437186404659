import { useState, useEffect, ReactNode } from 'react';
import { useCartContext } from 'hooks';
import { Modal } from '.';
import DialogContent from '@mui/material/DialogContent';
import { Loading, Money } from 'components';
import { Rate } from 'providers/CartProvider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export const RatesModal = ({ 
    trigger = <></>,
    open = false,
    onClose = () => {},
    force = false,
}:{
    trigger?: ReactNode | string,
    open?: boolean;
    onClose?: () => void;
    force?: boolean;
}) => {

    const [active, setActive] = useState<boolean>(open);
    const [localRate, setLocalRate] = useState<Rate>();

    const {
        rates,
        setRate,
        getRates,
        rateError,
        clearRateError,
        address,
    } = useCartContext();

    useEffect(() => {
        setActive(open);
    },[open]);

    useEffect(() => {
        (async () => {
            if ((!rateError || force) && active && !rates?.length && address && getRates) await getRates(address);
        })()
    },[active, rates, address, getRates, rateError, force]);

    const handleOpen = () => {
        clearRateError!();
        setActive(true);
    }

    const handleClose = () => {
        setActive(false);
        onClose();
    }

    return (
        <Modal 
            trigger={trigger}
            open={active}
            title={'Select Shipping Method'}
            opened={() => handleOpen()}
            closed={() => setActive(false)}
        >
            <DialogContent dividers>
                {rateError && (
                    <>
                        Could not calculate shipping rates.  Please contact us to get shipping rates for your order.
                    </>
                )}
                {!rates?.length && !rateError && <Loading/>}
                {!!rates?.length && (
                    <Box>
                        <p>Select a shipping method below. Please note that delivery times are estimates once shipped.</p>
                        <FormControl>
                            <RadioGroup
                                onChange={(e) => {
                                    const r = rates.find(r => r.id === e.target.value);
                                    if (r) setLocalRate!(r as Rate);
                                }}
                            >
                                { rates.sort((a, b) => a.total - b.total).map(r => {
                                    const { id, name, provider, total, delivery } = r;
                                    return (
                                        <Box mb={1}>
                                            <FormControlLabel 
                                                value={id} 
                                                control={<Radio />} 
                                                label={<><span style={{fontWeight:'bolder'}}><Money value={total}/></span>: {provider} {name} ({delivery})</>} 
                                            />
                                        </Box>
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button 
                    color='primary' 
                    disabled={!localRate} 
                    onClick={() => {
                        if (!localRate || !setRate) return;
                        setRate!(localRate as Rate);
                        handleClose();
                    }}
                >
                    Select Rate
                </Button>
            </DialogActions>
        </Modal>
    )
}