import { ReactNode, createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { customAlphabet } from 'nanoid';
import { useFirebaseContext } from 'hooks';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';

const nanoid = customAlphabet('qwertyuioplkjhgfdsazxcvbnm1234567890');

const itemKey = '__$tkt_$shop__';

export interface Product {
    id: string;
    name: string;
    price: number;
    desc?: string;
}

export interface CartItem extends Product {
    rowId: string;
    ble?: string;
    buttons?: string;
    total: number;
}

export interface Address {
    name: string;
    email: string;
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
}

export interface Rate {
    id: string;
    shipment_id?: string;
    provider: string;
    method: string;
    total: number;
    currency: string;
    delivery?: string;
    name: string;
    terms?: string;
    image?: string;
}

export interface CartContextProps {
    cart: CartItem[];
    address: Address; 
    rates: Rate[];
    rate: Rate;
    rateError: boolean;
    setRate: (r: Rate | undefined) => void;
    total: number;
    setAddress: (a: Address) => void;
    addToCart: (product: Omit<CartItem, 'total' | 'rowId'>) => void;
    removeFromCart: (product: CartItem) => void;
    resetCart: () => void;
    getRates: (a?: Address) => Promise<Rate[]>;
    clearRateError: () => void;
    addOnPrice: (buttons: string, model: string) => number;
    products: Product[];
    defaultRate: Rate;
    stripePromise: any;
    stripeOptions: StripeElementsOptions;
}

const defaultRate: Rate = {
    provider: 'USPS',
    method: 'priority',
    total: 12,
    id: 'default',
    currency: 'USD',
    name: 'Priority Mail',
    delivery: '3 days',
}

export const CartContext = createContext<Partial<CartContextProps>>({});

export const CartProvider = ({ children }:{ children: ReactNode}) => {
    
    const [cart, setCart] = useState<CartItem[]>([]);
    const [address, setAddress] = useState<Address>();
    const [rates, setRates] = useState<Rate[]>();
    const [rate, setRate] = useState<Rate | undefined>();
    const [rateError, setRateError] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    const {
        callable,
    } = useFirebaseContext();

    useEffect(() => {
        try {
            let s = window.localStorage.getItem(itemKey);
            let c = [];
            if (s) {
                c = JSON.parse(s);
            }
            setCart(c);
        } catch (err: any) {
            setCart([]);
        }
    },[]);

    useEffect(() => {
        if (!cart?.length && rate) {
            setTotal(0);
            //setRate(undefined);
        }
        let t = cart.reduce((t, next) => {
            t += next.price + addOnPrice(next.buttons || '', next.id);
            return t;
        }, 0) + (rate?.total || 0);
        setTotal(t);
    },[cart, rate]);

    const stripePromise = useMemo(() => {
        return loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
    },[]);

    const stripeOptions = useMemo((): StripeElementsOptions => {
        return {
            mode: 'payment',
            amount: (total || 0) * 100,
            currency: 'usd',
            paymentMethodCreation: 'manual',
            // Fully customizable with appearance API.
            appearance: {
                theme: 'stripe',
            },
        }
    },[total]);

    const updateAddress = useCallback((a: Address) => {
        if (rateError) clearRateError();
        if (address?.country === 'US') 
            if (!rate) setRate(defaultRate);
        else 
            if (rate) setRate(undefined);
        setAddress(a);
    }, []);

    const products = useMemo(() => ([
        { 
            id: 'TKTSTD',
            name: 'TK-Talkie Standard',
            price: 180,
            desc: '',
        },{
            id: 'TKTIMP',
            name: 'TK-Talkie Imperial',
            price: 205,
            desc: 'Includes single-button control',
        },{
            id: 'TKTCOM', 
            name: 'TK-Talkie Commander',
            price: 235,
            desc: 'Includes single 3-button Control Glove',
        },{
            id: 'TKTELT',
            name: 'TK-Talkie Elite',
            price: 285,
            desc: 'Includes two (2) 3-button Control Gloves'
        }
    ]), []);

    const addOnPrice = (buttons: string, model: string) => {
        let t = 0;
        switch (buttons) {
            case 'large':
            case 'large1':
                t += model === 'TKTIMP' ? 5 : 10;
                break;
            case 'large2':
                t += 20;
                break;
        }
        return t;
    }
    /**
     * Recalculate shipping based on previously selected rate
     * @returns 
     */
    const recalculateShipping = async () => {
        if (!address || !rate || !cart?.length) return;
        const r: Rate[] = await getRates();
        const newRate = r.find(r => r.provider === rate.provider && r.method === rate.method);
        setRate(newRate);
    }

    const addToCart = async (product: Omit<CartItem, 'total' | 'rowId'>) => {
        if (cart?.length >= 10) return;
        const c = Array.from(cart);
        c.push({ 
            rowId: nanoid(8),
            ...product,
            total: product.price + addOnPrice(product.buttons || '', product.id),
        })
        setCart(c);
        recalculateShipping();
    }

    const removeFromCart = async (product: CartItem) => {
        const index = cart?.findIndex(p => p.rowId === product.rowId); 
        if (index >= 0) {
            const c = Array.from(cart);
            c.splice(index, 1);
            setCart(c);
            window.localStorage.setItem(itemKey, JSON.stringify(c));
        }
        recalculateShipping();
    }

    const clearRateError = () => setRateError(false);

    const resetCart = () => {
        setCart([]);
        setRate(undefined);
        setRates([]);
        window.localStorage.setItem(itemKey, JSON.stringify([]));
    }

    const getRates = async (a?: Address) => {

        setRate(undefined);
        setRateError(false);

        if (!a) a = address;

        // weight in oz
        const weight = 6 * cart.length;

        const total = cart.reduce((total, next) => total += next.price, 0);
        
        const { data } = await callable!(
            'shipping-rates', 
            { 
                ...a, 
                weight, 
                total,
            }
        );

        setRates(data.rates || []);
        if (!data.rates?.length) setRateError(true);

        return data.rates || [];

    }

    return (
        <CartContext.Provider value={{
            cart, 
            addToCart, 
            resetCart, 
            removeFromCart,
            address,
            setAddress: updateAddress,
            getRates,
            rates,
            rate,
            setRate,
            rateError,
            clearRateError,
            total,
            addOnPrice,
            products,
            defaultRate,
            stripePromise,
            stripeOptions,
        }}>
            {children}
        </CartContext.Provider>
    )

}