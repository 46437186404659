import CurrencyFormat from 'react-currency-format';

export const Money = ({value = 0, basic = false, decimal = 2}) => (
  <><CurrencyFormat
      displayType={'text'}
      decimalScale={decimal}
      fixedDecimalScale={true}
      value={value}
      thousandSeparator={true}
      prefix={basic ? '' : '$'}
  /></>
);
