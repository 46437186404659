import cloneDeep from 'lodash/cloneDeep';
import { CdnImage, cdnImage } from 'components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const LightBoxImage = (props: any) => {
    let properties = cloneDeep(props)
    if (!props.as) {
        properties['data-featherlight'] = cdnImage(props.src)
    }
    const label = props.label;
    delete properties.label
    return (
        <Box display="flex" flexDirection="column" alignItems='center'>
            <CdnImage {...properties}/> 
            { (label && properties.alt) && <Typography fontSize="small" textAlign='center'>{properties.alt}</Typography>}
        </Box>
    )
}

export default LightBoxImage