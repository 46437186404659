import { Link as RouterLink, LinkProps } from 'react-router-dom';
import Link from '@mui/material/Link';

export const InternalLink = ({ to, children, color = 'primary', style, ...rest }: LinkProps) => (
    <Link 
        component={RouterLink} {...rest} 
        to={to}
        color={color}
        style={{ ...style }}
    >
        {children}
    </Link>
)