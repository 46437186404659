import { ReactElement, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { SiteTitle, NavMenu, Money } from 'components';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { ROUTES } from 'routes';
import { useCartContext } from 'hooks';
import { InternalLink } from 'components';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Typography } from '@mui/material';

function ElevationScroll({ children }:{ children: ReactElement}) {

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
}

export const TopBar = () => {

    const { total = 0, cart } = useCartContext();

    const navigate = useNavigate();

    return (
        <ElevationScroll>
            <AppBar 
                position="sticky" 
                sx={{ 
                    backgroundColor: '#1A1A1A', 
                    padding: { xs: 0, md: '5px 30px 5px 30px' }
                }}
            >
                <Toolbar>
                    <SiteTitle/>
                    <Box sx={{ flexGrow: 1 }} />
                    { !cart?.length && (
                        <Box pr={2}>
                            <InternalLink to={ROUTES.shop}>
                                <Typography sx={{ color: 'white' }} fontWeight='bold'>
                                    Buy TK-Talkie
                                </Typography>
                            </InternalLink>
                        </Box>
                    )}
                    {!!cart?.length && (
                        <Box 
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(!!cart?.length ? ROUTES.shop_cart : ROUTES.shop)}
                        >
                            <Box display='flex' flexDirection='row'>
                                <ShoppingCartOutlinedIcon/> 
                                <Box>
                                    <Money value={total}/>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Box>
                        <NavMenu/>
                    </Box>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}
