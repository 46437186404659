import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { Footer, TopBar, Header, ReturnHeader } from 'components';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';

interface DefaultLayoutProps {
    title?: string;
    noHeader?: boolean;
    showTitle?: boolean;
    internal?: boolean;
    returnTo?: string;
    maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | false;
    children: ReactNode;
}

export const Default = ({ 
    title = '', 
    noHeader = false,
    showTitle = true, 
    internal = true, 
    returnTo = '',
    maxWidth = false,
    children,
}: DefaultLayoutProps ) => {

    return (
        <>
            <Helmet>
                <title>
                    {!title.includes('TK-Talkie') ? 'TK-Talkie' : ''}{title ? `: ${title}` : ''}
                </title>
                <meta property="og:url" content="http://www.tktalkie.com/" />
                <meta property="og:title" content={`${title}`} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://cdn.tktalkie.com/screenshot.png" />
            </Helmet>
            <Box 
                display="flex" 
                flexDirection="column" 
                minHeight={'100vh'} 
                sx={{
                    backgroundColor: '#121212'
                }}
            >
                {/* top bar */}
                {!noHeader && (
                    <Box flexGrow={0}>
                        <TopBar/>
                    </Box>
                )}
                {/* content */}
                <Box flexGrow={1} 
                    pt={internal ? 4 : 0}
                    pb={4}
                    sx={{
                        backgroundColor: internal || noHeader ? '#ffffff' : '#121212',
                    }}
                >
                    <Container 
                        maxWidth={!maxWidth ? internal ? 'lg': maxWidth : maxWidth} 
                        sx={{
                            backgroundColor: '#ffffff',
                            padding: internal ? 'initial' : '0 !important',
                        }}
                    >
                        {(showTitle && title) 
                            ? returnTo 
                                ? <ReturnHeader as="h1" variant="h3" mb={4} to={returnTo}>{title}</ReturnHeader>
                                : <Header as="h1" variant='h3' mb={4}>{title}</Header>
                            : null
                        }
                        { children }
                    </Container>
                </Box>
                {/* footer */}
                {!noHeader && (
                    <Box flexGrow={0}>
                        <Footer/>
                    </Box>
                )}
            </Box>
        </>
    )

}