//import Disclaimer from "./Disclaimer";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Stack from '@mui/material/Stack';

const columns = [
    {
        title: 'About',
        links: [{
            label: 'About TK-Talkie',
            path: '/about',
        },{
            label: 'Contact Us',
            path: '/contact',
        },{
            label: 'Privacy Policy',
            path: '/privacy',
        }]
    },{
        title: 'Help and Support',
        links: [{
            label: 'Support and Guides',
            path: '/support',
        },{
            label: 'Tutorial',
            path: '/tutorial',
        },{
            label: 'Latest Version',
            path: '/v5',
        }]
    },{
        title: 'Downloads',
        links: [{
            label: 'Cases',
            path: '/downloads/cases',
        },{
            label: 'Sounds',
            path: '/downloads/sounds',
        },{
            label: 'Profiles',
            path: '/downloads/profiles',
        }]
    }
]
export const Footer = () => {
    return (
        <Box padding={3} sx={{ backgroundColor: '#000000', color: '#ffffff' }}>
            <Grid container>
                {columns.map(({ title, links }) => {
                    return (
                        <Grid item container md={3} alignContent={'flex-start'}>
                            <Typography paragraph fontWeight='bold' width='100%' mb={2}>
                                {title}
                            </Typography>
                            <Box>
                                <Stack direction="column" rowGap={1}>
                                    {links.map(({ label, path }) => (
                                        <Link style={{color:'#fffff0', textDecoration:'none'}} to={path}>
                                            {label}
                                        </Link>
                                    ))}
                                </Stack>
                            </Box>
                        </Grid>
                    )
                })}
              <Grid item container md={3}>
                <Stack direction="column" rowGap={1}>
                    <Box>
                        Join the TK-Talkie Community!
                    </Box>
                    <Stack direction="row" columnGap={2}>
                        <FacebookIcon/>
                        <YouTubeIcon/>
                    </Stack>
                    <Box>
                        <Typography paragraph fontSize='small'>
                            &copy; 2014-{new Date().getFullYear()} Because...Interwebs!
                        </Typography> 
                    </Box>
                </Stack>
              </Grid>
            </Grid>
        </Box>
    );

};

