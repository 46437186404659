import {ROUTES} from 'routes';
import {Logo, InternalLink} from 'components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export const SiteTitle = () => {

    return (
      <InternalLink to={ROUTES.home} color="#fffff0">
        <Grid container columns={2} spacing={2}>
          <Grid item sx={{
              paddingTop: '6px',
              verticalAlign: 'middle',
            }}
          >
            <Box mt={1} sx={{ textTransform: 'uppercase' }}>{ process.env.REACT_APP_NAME }</Box>
          </Grid>
        </Grid>  
      </InternalLink>  
    )

}
