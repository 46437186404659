import Stack from '@mui/material/Stack';
import { CdnImage, ExternalLink } from 'components';

export const AppButtonsV2 = () => {
    return (
        <Stack direction='row'>
            <ExternalLink 
                to={'https://play.google.com/store/apps/details?id=com.becauseinterwebs.tktalkie2'} 
                target={'_new'}
            >
                <CdnImage 
                    src="google_play.png"
                    permanent
                    width={225}
                />
            </ExternalLink>
            <ExternalLink
                to={'https://apps.apple.com/us/app/tk-talkie-v2/id1372586454'}
                target='_new'
            >
                <CdnImage 
                    src="app_store.png"
                    target="_new"
                    permanent
                    width={200}
                />
            </ExternalLink>
        </Stack>
    )
}

export default AppButtonsV2;