import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Name } from './Vars';

const cases = [
    {
        file : 'lid-blank.stl',
        name : 'Blank Lid',
        desc : 'This fits all models and is blank, allowing you to customize the top.',
        img  : 'blank_lid.png'
    },
    {
        file : 'lid.stl',
        name : 'TK-Talkie Lid',
        desc : 'Sames as the blank lid, but with the TK-Talkie logo on it.',
        img  : 'lid.png'
    },
    {
        file : 'standard.stl',
        name : 'Standard Case',
        desc : 'The Standard case does not have any openings in the back for button controls.',
        img  : 'standard.png'
    },
    {
        file : 'imperial.stl',
        name : 'Imperial Case',
        desc : 'The Imperial case has a single opening (same size as front openings) to accommodate a 3.5mm jack for a single PTT/Control Button.',
        img  : 'imperial.png'
    },
    {
        file : 'commander.stl',
        name : 'Commander Case',
        desc : 'The Commander case has a single, larger opening in the back to accommodate a 4-pole jack necessary for a Control Glove.',
        img  : 'commander.png'
    },
    {
        file : 'elite.stl',
        name : 'Elite Case',
        desc : 'The Elite case has two (2) openings in the back for two (2) 4-pole jacks for Control Gloves.',
        img  : 'elite.png'
    }
]

export const Cases = () => {

    return (
        <>
            { cases.map(({ name, img, file, desc }) => (
                <Box 
                    sx={{
                        maxWidth: '275px', 
                        height: '385px', 
                        display: 'inline-block', 
                        marginRight: '20px', 
                        border: '1px solid #e2e2e2',
                        marginBottom: '20px',
                    }}
                >
                    <Box height="100%" display="flex" flexDirection="column">
                        <Box padding={1}>
                            <Typography fontSize='1.2em' fontWeight='bolder' textAlign='center'>
                                {name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                height: '200px',
                                backgroundColor: '#000000',
                                backgroundImage: `url(https://cdn.tktalkie.com/case/${img})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                            }}
                        />
                        <Box flexGrow={1} padding={2}>
                            <Typography fontSize='smaller'>
                                {desc}
                            </Typography>
                        </Box>
                        <Box flexGrow={0} pl={2} alignContent='flex-end' textAlign='center'>
                            <Button component="a" href={`https://cdn.tktalkie.com/public/stl/case/${file}`}>Download</Button>
                        </Box>
                    </Box>
                </Box>

            ))}
        </>
    )

}