import { ButtonLink, SecondaryButtonLink, CdnImage, TKTalkieMap, YoutubeVideo } from 'components';
import { withPublicPage } from 'hoc';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'routes';
import ArrowIconEast from '@mui/icons-material/East';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const features = [
    'Real-time voice processing including pitch shifting (raise/lower voice)',
    'Dry/wet mixing (mixed processed voice with unprocessed voice)',
    '5-band equalizer',
    'Effects such as bitcrusher, flanger and chorus',
    'Control Gloves with up to 12 buttons',
    'Multiple costume profiles'
];

const Home = () => {

    return (
        <>
            <Box 
                padding={2} 
                width='100%'
                sx={{
                    backgroundColor: '#000000', 
                    color: '#ffffff', 
                    textAlign: 'center',
                }}
            >
                <CdnImage src="tktalkiebanner.png" sx={{maxWidth:'500px'}}/>
                <Box maxWidth='960px' margin={'40px auto'}>
                    <Typography fontSize='1.5em' fontWeight='bold'>
                        With real-time voice processing, sound effects, control gloves and more, TK-Talkie is ready to help you take your costuming to the next level.
                    </Typography>
                </Box>
                <ButtonLink to={ROUTES.about} variant="contained" endIcon={<ArrowIconEast/>}>
                    Learn More
                </ButtonLink>
            </Box>
            <Container>
                <Box padding={2}>
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            <Typography variant='h4' fontWeight='bolder' mb={1}>
                                Why TK-Talkie?
                            </Typography>
                            <Typography fontSize='1.2em'>
                                TK-Talkie offers many features that make it an indispensible part of your kit.
                            </Typography>
                            <Stack direction="column" rowGap={1} mt={2} mb={2}>
                                {features.map(s => (
                                    <Box>
                                        <KeyboardDoubleArrowRightIcon sx={{verticalAlign:'middle'}}/> {s}
                                    </Box>
                                ))}
                            </Stack>
                            <Typography paragraph fontSize='1.2em' fontWeight='bolder'>
                                ... and MUCH more!
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <YoutubeVideo url="https://www.youtube.com/embed/CfOPUnpBqpM" />
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{marginBottom:'40px'}}/>
                <Box>
                    <Typography paragraph variant="h4" fontWeight='bolder' textAlign='center'>
                        Build It or Buy It!
                    </Typography>
                    <Box mb={4}>
                        <Typography paragraph textAlign='center'>
                            TK-Talkie comes in several models that you can purchase from our store.
                        </Typography>
                        <Box textAlign='center'>
                            <ButtonLink variant="contained" to={ROUTES.store} endIcon={<ArrowIconEast/>}>
                                Visit the Store
                            </ButtonLink>
                        </Box>
                    </Box>
                    <Typography paragraph textAlign='center'>
                        If you are handy with a soldering iron, you can also follow one of our step-by-step tutorials and build your own.
                    </Typography>
                    <Box textAlign='center'>
                        <SecondaryButtonLink variant="contained" to={ROUTES.tutorials} endIcon={<ArrowIconEast/>}>
                            View the Tutorials
                        </SecondaryButtonLink>
                    </Box>
                </Box>
                <Divider sx={{marginTop:'40px'}}/>
                <Box textAlign='center' mt={4} mb={3} pb={5}>
                    <Typography paragraph variant="h4" fontWeight='bolder'>
                        Used Around the World
                    </Typography>
                    <Typography paragraph>
                        TK-Talkie has been used around the world by discerning costumers for over 10 years!
                    </Typography>
                    <TKTalkieMap/>
                </Box>
            </Container>
        </>
    )
}

export default withPublicPage({ title: 'Home', showTitle: false, internal: false })(Home);