export const sounds: Record<string, any> = {
    startup: {
        title: 'Startup Sounds',
        desc: 'These sounds are played as the TK-Talkie starts up.',
        files: [{
                title: "Short For A Stormtrooper",
                desc: "Princess Leia's iconic line from A New Hope.",
                archive: "short",
                file: "SHORT"
            },
            {
                title: "Droid Startup",
                desc: "TK-Talkie startup in a droid voice",
                archive: "droidstart",
                file: "DRDSTART"
            },
            {
                title: "Female Voice (UK) Startup",
                desc: "TK-Talkie startup in female UK voice",
                archive: "femalestartuk",
                file: "FSTARTUK"
            },
            {
                title: "Male Voice (UK) Startup",
                desc: "TK-Talkie startup in maile UK voice",
                archive: "malestartuk",
                file: "MSTARTUK"
            },
            {
                title: "Female Voice (AU) Startup",
                desc: "TK-Talkie startup in female AU voice",
                archive: "femalestartau",
                file: "FSTARTAU"
            },
            {
                title: "No Good To Me Dead",
                desc: "Boba Fett\'s iconic line from ESB",
                archive: "bobadead",
                file: "BOBADEAD"
            },
            {
                title: "Not a Jedi...Yet!",
                desc: "Startup sound for a Vader profile",
                archive: "notajedi",
                file: "NOTAJEDI"
            }
        ],
    },
    sleep: {
        title: "Sleep Sounds",
        desc: "Played when TK-Talkie enters sleep mode.",
        files: [{
                title: "Droid Sleep Mode",
                desc: "TK-Talkie sleep mode notice in a droid voice",
                archive: "droidlseep",
                file: "DRDSLEEP"
            },
            {
                title: "Female Voice (UK) Sleep Mode",
                desc: "TK-Talkie sleep mode notification in female UK voice",
                archive: "femalesleepuk",
                file: "FSLEEPUK"
            },
            {
                title: "Male Voice (UK) Sleep Mode",
                desc: "TK-Talkie sleep mode notification in male UK voice",
                archive: "malesleepuk",
                file: "MSLEEPUK"
            },
            {
                title: "Female Voice (AU) Sleep Mode",
                desc: "TK-Talkie sleep mode notification in female AU voice",
                archive: "femalesleepau",
                file: "FSLEEPAU"
            },
            {
                title: "Boring Conversation Anyway",
                desc: "The classic (improvised) line from ANH",
                archive: "boring",
                file: "BORING"
            }
        ],
    },
    effects : {
        title : "Voice Effects",
        desc : "These are the effects that play after you stop speaking, when you press/release the PTT button, etc.",
        zip: 'effects.zip',
        tar : 'effects.tar.gz',
        files: [
            'CLICK1.WAV',
            'CLICK2.WAV',
            'CLICK3.WAV',
            'CLICK4.WAV',
            'CLICK5.WAV',
            'CLICK6.WAV',
            'CLICK7.WAV',
            'CLICK8.WAV',
            'COMM1.WAV',
            'COMM2.WAV',
            'COMM3.WAV',
            'COMM4.WAV',
            'STATIC1.WAV',
            'STATIC10.WAV',
            'STATIC2.WAV',
            'STATIC3.WAV',
            'STATIC4.WAV',
            'STATIC5.WAV',
            'STATIC6.WAV',
            'STATIC7.WAV',
            'STATIC8.WAV',
            'STATIC9.WAV'
        ]
    },
    loops: {
        title: "Background Loops",
        desc: "Played continuously while TK-Talkie is running...great for adding some ambience!",
        archives: true,
        files: [{
                title: "TK (Stormtrooper) Background Loop",
                archive: "tkloop",
                file: "TKLOOP"
            },
            {
                title: "TB (Biker Scout) Background Loop",
                archive: "bikeloop",
                file: "BIKELOOP"
            },
            {
                title: "Clone Trooper Background Loop",
                archive: "cloneloop",
                file: "CLONLOOP"
            },
            {
                title: "TD (Sandtrooper) Background Loop",
                archive: "sandloop",
                file: "SANDLOOP"
            },
            {
                title: "TI (Tie Pilot) Background Loop",
                archive: "tieloop",
                file: "TIELOOP"
            },
            {
                title: "Vader Breathing Loop",
                archive: "vaderbreathe",
                file: "BREATHE"
            }
        ],
    },
    tk: {
        title: "Stormtrooper (TK) Sounds",
        desc: "Various sounds for use with a TK.",
        zip: 'tk.zip',
        tar : 'tk.tar.gz',
        files: [{
                title: "Alright",
                archive: "ALRIGHT"
            },
            {
                title: "Blast 'em!",
                archive: "BLASTEM"
            },
            {
                title: "Blaster 1",
                archive: "BLASTER1"
            },
            {
                title: "Blaster 2",
                archive: "BLASTER2"
            },
            {
                title: "Sniper",
                archive: "SNIPER"
            },
            {
                title: "Stun",
                archive: "STUN"
            },
            {
                title: "Follow Me",
                archive: "FOLLOWME"
            },
            {
                title: "How long have you had these droids?",
                archive: "HOWLONG"
            },
            {
                title: "It's them! Blast em!",
                archive: "ITSTHEM1"
            },
            {
                title: "Alright men, load your weapons.",
                archive: "LOADWEAP"
            },
            {
                title: "Look sir, droids!",
                archive: "LOOKDROI"
            },
            {
                title: "Look! There!",
                archive: "LOOKTHER"
            },
            {
                title: "Move along...move along...",
                archive: "MOVEALNG"
            },
            {
                title: "There's no one here",
                archive: "NOONEHER"
            },
            {
                title: "We don't need to see his identification",
                archive: "NOSEEID"
            },
            {
                title: "These aren't the droids we're looking for.",
                archive: "NOTDROID"
            },
            {
                title: "Open the blast doors! Open the blast doors!",
                archive: "OPENDOOR"
            },
            {
                title: "Let me see your identification",
                archive: "SEEID"
            },
            {
                title: "There's one...set for stun",
                archive: "SETSTUN1"
            },
            {
                title: "Set for stun",
                archive: "SETSTUN2"
            },
            {
                title: "There's one!",
                archive: "THEREONE"
            },
            {
                title: "Do you know what's going on?",
                archive: "WHATGOIN"
            }
        ],
    },
    jawa: {
        title: "Jawa",
        desc: "Although you can use the Jawa profile to sound like these pesky creatures, these sounds can be used with the Control Glove.",
        zip : 'jawa.zip',
        tar : 'jawa.tar.gz',
        files: [{
                title: "Jawa 1",
                archive: "jawa1",
                file: "jawa1.wav"
            },
            {
                title: "Jawa 2",
                archive: "jawa2",
                file: "jawa2.wav"
            },
            {
                title: "Jawa 3",
                archive: "jawa3",
                file: "jawa3.wav"
            },
            {
                title: "Jawa 4",
                archive: "jawa4",
                file: "jawa4.wav"
            },
            {
                title: "Jawa 5",
                archive: "jawa5",
                file: "jawa5.wav"
            },
            {
                title: "Jawa 6",
                archive: "jawa6",
                file: "jawa6.wav"
            },
            {
                title: "Jawa 7",
                archive: "jawa7",
                file: "jawa7.wav"
            },
            {
                title: "Jawa 8",
                archive: "jawa8",
                file: "jawa8.wav"
            },
            {
                title: "Jawa 9",
                archive: "jawa9",
                file: "jawa9.wav"
            },
            {
                title: "Jawa 10",
                archive: "jawa10",
                file: "jawa10.wav"
            },
            {
                title: "Jawa 11",
                archive: "jawa11",
                file: "jawa11.wav"
            },
            {
                title: "Jawa 12",
                archive: "jawa12",
                file: "jawa12.wav"
            }
        ]
    },
    ewok: {
        title: "Ewok",
        desc: "Here's a bunch of murder bear noises...",
        zip : 'ewok.zip',
        tar : 'ewok.tar.gz',
        files: [
            'AAH.wav',
            'CHATTER1.wav',
            'CHATTER10.wav',
            'CHATTER2.wav',
            'CHATTER3.wav',
            'CHATTER4.wav',
            'CHATTER5.wav',
            'CHATTER6.wav',
            'CHATTER7.wav',
            'CHATTER8.wav',
            'CHATTER9.wav',
            'CHO.wav',
            'EXCITED1.wav',
            'EXCITED2.wav',
            'EXCITED3.wav',
            'FINGAWA.wav',
            'GOO.wav',
            'GOO2.wav',
            'GROWL1.wav',
            'GROWL2.wav',
            'GROWL3.wav',
            'GUNDAH.wav',
            'GUPANUPA.wav',
            'GUTAH.wav',
            'HEY.wav',
            'HEY2.wav',
            'HEY3.wav',
            'HEY4.wav',
            'JAMBOO.wav',
            'MAFWATA.wav',
            'MANA.wav',
            'MANACHU.wav',
            'MANATUTU.wav',
            'MEH.wav',
            'MEH2.wav',
            'MEH3.wav',
            'MKENI.wav',
            'MKENICHO.wav',
            'NOGROWL.wav',
            'OGAH.wav',
            'OOH1.wav',
            'OOH2.wav',
            'OTOH.wav',
            'OYEHEH.wav',
            'PANT.wav',
            'SCREAM.wav',
            'SCREAM1.wav',
            'SCREAM2.wav',
            'SNEEZE.wav',
            'SNIFF.wav',
            'TUTU.wav',
            'UHH.wav',
            'USH.wav',
            'USTOGAH.wav',
            'VANOO.wav',
            'VANOO2.wav',
            'VANUPA.wav',
            'WORRIED.wav',
            'WUNDAA.wav',
            'YAHAA.wav',
            'YELL.wav',
            'YOTOH.wav',
            'YUBYUB.wav'
        ]
    },
    vader : {
        title : "Darth Vader Sounds",
        desc : "Word of wisdom from the Dark Lord himself...",
        zip : 'vader.zip',
        tar : 'vader.tar.gz',
        files : [
            'APOLOGY.WAV',
            'CORRECT.WAV',
            'DESTROY.WAV',
            'NOOOOOOO.WAV',
            'POINTLES.WAV',
            'POWERFUL.WAV',
            'STRONG.WAV'
        ]
    },
    wookie : {
        title : "Wookie Sounds",
        desc : "Laugh it up, fuzzball...",
        zip : 'wookie.zip',
        tar : 'wookie.tar.gz',
        files : [
            'ANGRY.wav',
            'ANGRY2.wav',
            'CHATTER.wav',
            'COMPLAIN.wav',
            'GUTROAR.wav',
            'LAUGH.wav',
            'LONGROAR.wav',
            'QUERY.wav',
            'QUERY2.wav',
            'QUERY3.wav',
            'QUESTION.wav',
            'ROAR1.wav',
            'ROAR2.wav',
            'ROAR3.wav',
            'ROAR4.wav',
            'ROAR6.wav',
            'ROAR7.wav',
            'ROAR8.wav',
            'ROAR9.wav',
            'SERIES1.wav',
            'WAIL.wav',
            'WAIL2.wav',
            'WAIL3.wav',
            'WAIL4.wav',
            'WAIL5.wav',
            'WHINE.wav',
            'WHINE2.wav',
            'WOOH.wav'
        ]
    }
}