import { ReactNode } from 'react';
import LightBoxImage from './LightBoxImage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Header } from 'components';

interface StepImage {
    url: string;
    link?: string;
    alt?: string;
}

export const Step = ({ 
    id = '', 
    num = 0, 
    title='', 
    imageSize='medium', 
    images = [], 
    children, 
    parent = false 
}:{
    id?: string | number,
    num?: number,
    title?: string,
    imageSize?: string,
    images?: StepImage[] | string[],
    children?: ReactNode,
    parent?: boolean;
}) => {

    // determine column sizes based on images and imageSize
    let columns       = 1
    let leftColWidth  = 16
    let rightColWidth = 0
    
    if (images && images.length > 0) {
        columns = 2;
        switch (imageSize) {
            case 'small':
                leftColWidth = 10
                rightColWidth = 2
                break;
            default:
                leftColWidth = 8
                rightColWidth = 4
                break;
        }
    }

    return (
        <Box width={'100%'} mb={4}>
            <Grid container columnSpacing={2}>
                <Grid item xs={16} md={columns > 1 ? 10 : 16}>
                    <Header as={parent ? "h2" : "h3"} variant={parent ? "h3" : "h4"}>
                        {title}
                    </Header>
                    {children}
                </Grid>
                { columns > 1
                    ?   <Grid item xs={16} md={2} textAlign='center'>
                        { (images && images.length > 0) && 
                            images.map(image => {
                                if (typeof image !== 'object') {
                                    image = { url : image }
                                }
                                return (
                                    <Box mb={2} textAlign='center'>
                                        {image.link 
                                            ? <LightBoxImage height={'200px'} width={'200px'} label as="a" href={image.link} target='_new' alt={image.alt} bordered rounded src={`${image.url}`}/>
                                            : <LightBoxImage height={'200px'} width={'200px'} label alt={image.alt} bordered rounded src={`${image.url}`}/>
                                        }
                                    </Box>
                                )
                            })
                        }
                        </Grid>
                    : null
                }    
            </Grid>
        </Box>
    )
}

export default Step