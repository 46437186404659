import { useEffect, useRef, useState } from "react";
import { locations } from "lib";
import Box from '@mui/material/Box';
import { cdnImage } from 'components';

import { Loader } from "@googlemaps/js-api-loader"

const defaultProps = {
  center: {
    lat: 37.0902, //33.589886,
    lng: -95.7129, //-7.603869
  },
  zoom: 2,
};

export const TKTalkieMap = () => {

  const loader = useRef<Loader>();
  const map = useRef<google.maps.Map>();

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (loader.current) return;
      loader.current = new Loader({
        apiKey: process.env.REACT_APP_MAP_KEY || '',
        version: "weekly",
      })
      await loader.current.load().then(() => setLoaded(true));
    })()
  },[]);

  useEffect(() => {
    (async () => {
      if (!loaded || !loader.current) return;
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      map.current = new Map(document.getElementById("map") as HTMLElement, defaultProps);
      // add icons 
      for (const { lat, lng } of locations) {
          new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: { 
              url: cdnImage('map/tk.png'),
              scaledSize: new google.maps.Size(20, 20),
            },
            map: map.current,
          })
      }
    })()
  },[loaded, loader]);

  return (
    // Important! Always set the container height explicitly
    <Box width='100%' height='500px'>
      <div id="map" style={{width:'100%', height:'100%'}}/>
    </Box>
  );

}

export default TKTalkieMap;

