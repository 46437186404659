import { PropsWithChildren } from 'react';
import { Image } from 'mui-image';

export interface ImageProps extends PropsWithChildren {
    height?: any;
    width?: any;
    maxHeight?: any; 
    maxWidth?: any;
    rounded?: boolean;
    circular?: boolean;
    shadow?: boolean;
    link?: boolean;
    alt?: string;
    src: string;
    permanent?: boolean;
    nofade?: boolean;
    noCache?: boolean;
}

export const NOIMAGE = '/img/noimage.jpg';

export const CdnImage = (props: any) => {
    const { rounded, circular, shadow, link, sx, noCache = false, ...rest } = props;
    const opts = {
        borderRadius: rounded ? '10px' : circular ? '50%' : 0,
        boxShadow: shadow ? '3px 3px 3px' : 'none',
        cursor: link ? 'cursor: pointer;' : 'inherit',
    }
    const { nofade = false, ...remain } = rest;
    let { src = NOIMAGE, permanent, ...left } = remain;
    if (!src) src = NOIMAGE;
    if (src === NOIMAGE) permanent = true;
    return <Image sx={{...sx, ...opts}} src={cdnImage(src, permanent, noCache)} {...left} duration={nofade ? 0 : 320}/>
}

export const cdnImage = (src = NOIMAGE, permanent = false, noCache = false) => {
    if (!src) src = NOIMAGE;
    if (src === NOIMAGE) permanent = true;
    if (noCache) {
        const t = new Date().getTime();
        if (src.includes('?')) 
            src += '&'
        else 
            src += '?'
        src += t;
    }
    return src.indexOf('http') >= 0 
        ? src 
        : `${process.env.REACT_APP_CDN}/${src}`;
}


