import { createContext, useState, useEffect, ReactNode } from "react";
import { GenericObject, Loading } from "components";
import { useFirebaseContext } from 'hooks';
import { CollectionReference, DocumentReference, collection, doc } from 'firebase/firestore';

export interface Refs {
  ordersRef: CollectionReference;
  orderRef: (id: string) => DocumentReference;
}

export const RefsContext = createContext<Partial<Refs>>({} as GenericObject);
export const RefsConsumer = RefsContext.Consumer;

export const RefsProvider = ({ children }:{ children: ReactNode }) => {

  const [refs, setRefs] = useState<GenericObject>();

  const {db} = useFirebaseContext();

  useEffect(() => {
    if (db) {
        const ordersRef = collection(db, 'orders');
        const orderRef = (id: string) => doc(ordersRef, id);

        setRefs({
            ordersRef,
            orderRef,
        });
    }
  },[db]);

  if (!refs || !db) return <Loading/>;

  return (
    <RefsContext.Provider value={{...refs}}>
      {children}
    </RefsContext.Provider>
  );
  
};
