import styled from "@emotion/styled";

export const YoutubeVideo = ({ url }:{url: string}) => {
  return (
    <VideoContainer>
      <iframe 
        title='TK-Talkie Video'
        width="100%"
        height="360"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  padding: 1em;
  min-height: 320px;
  @media (min-width: 768px) {
    min-height: 480px;
  }
`;
