import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { useFirebaseContext, useCartContext } from 'hooks';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
//import Typography from '@mui/material/Typography';
//import Stack from '@mui/material/Stack';
//import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
//import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import Badge from '@mui/material/Badge';
//import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
//import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
//import EventSeatIcon from '@mui/icons-material/EventSeat';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
//import { UserAvatar } from 'components';
import BuildIcon from '@mui/icons-material/Build';
import DownloadIcon from '@mui/icons-material/Download';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

export const NavMenu = () => {

    const navigate = useNavigate();

    const { 
        cart, 
        rate,
    } = useCartContext();

    const { firebaseUser } = useFirebaseContext();

    //const { userProfile } = useSessionContext();

    const {notificationCount = 0, messageCount = 0} = {};

    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const toggleOpen = () => setOpen(prev => !prev);

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
        toggleOpen();
    }

    const nav = (to: string) => {
        toggleOpen();
        navigate(to);
    }

    const UserMenu = () => (
        <>
            {/*<MenuItem onClick={() => nav(ROUTES.me)} sx={{textAlign: 'center'}}>
                <Stack>
                    <Typography textAlign="center" variant="button" sx={{display:'block'}}>{userProfile?.displayName || firebaseUser?.displayName}</Typography>
                    <Typography fontSize="small" textAlign="center" sx={{display:'block'}}>{firebaseUser?.email}</Typography>
                </Stack>
    </MenuItem>*/}
            <Divider/>
            <Divider />
            <MenuItem onClick={() => nav(ROUTES.signout)}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </>
    )

    const PublicMenu = () => (
        <>
            <MenuItem onClick={() => nav(ROUTES.home)}>
                <ListItemIcon>
                    <HomeIcon/>
                </ListItemIcon>
                Home
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.tutorials)}>
                <ListItemIcon>
                    <BuildIcon/>
                </ListItemIcon>
                Tutorials
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.downloads)}>
                <ListItemIcon>
                    <DownloadIcon/>
                </ListItemIcon>
                Downloads
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.app)}>
                <ListItemIcon>
                    <PhoneIphoneIcon/>
                </ListItemIcon>
                Mobile App
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => nav(ROUTES.shop)}>
                <ListItemIcon>
                    <StorefrontIcon/>
                </ListItemIcon>
                Shop
            </MenuItem>
            {!!cart?.length && (
                <MenuItem onClick={() => nav(ROUTES.shop_cart)}>
                    <ListItemIcon>
                        <ShoppingCartOutlinedIcon/>
                    </ListItemIcon>
                    Cart
                </MenuItem>
            )}
            {(!!cart?.length && rate) && (
                <MenuItem onClick={() => nav(ROUTES.shop_cart)}>
                    <ListItemIcon>
                        <PointOfSaleIcon/>
                    </ListItemIcon>
                    Checkout
                </MenuItem>
            )}
            <MenuItem onClick={() => nav(ROUTES.shop_order_status)}>
                <ListItemIcon>
                    <AssignmentTurnedInOutlinedIcon/>
                </ListItemIcon>
                Order Status
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => nav(ROUTES.support)}>
                <ListItemIcon>
                    <HelpOutlineIcon/>
                </ListItemIcon>
                Help
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.contact)}>
                <ListItemIcon>
                    <AlternateEmailIcon/>
                </ListItemIcon>
                Contact Us
            </MenuItem>
        </>
    )

    return (
        <>
        <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'nav-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                { firebaseUser 
                    ? (
                            <Badge color="error" variant="dot" badgeContent={notificationCount + messageCount} max={9}>
                                {/*<UserAvatar nocache/>*/}
                            </Badge>
                    )   : <MenuIcon htmlColor="#fffff0"/>
                }
        </IconButton>
        <Menu
                anchorEl={anchorEl}
                id="nav-menu"
                open={open}
                onClick={toggleOpen}
                slotProps={{ 
                    paper: { 
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                }
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                { firebaseUser ? <UserMenu/> : <PublicMenu/> }
            </Menu>
        </>
    )
}